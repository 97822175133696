import React, { useState, useEffect, useCallback } from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Zap, Wrench, CheckCircle, Truck, Users } from 'lucide-react';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [data, setData] = useState({
    inventory: [
      { name: 'Item A', current: 300, reorder: 100 },
      { name: 'Item B', current: 150, reorder: 200 },
      { name: 'Item C', current: 400, reorder: 300 },
      { name: 'Item D', current: 80, reorder: 150 },
    ],
    maintenance: [
      { name: 'Machine 1', health: 85, nextMaintenance: 15, predictedFailure: '2024-09-15' },
      { name: 'Machine 2', health: 92, nextMaintenance: 30, predictedFailure: '2024-11-20' },
      { name: 'Machine 3', health: 78, nextMaintenance: 5, predictedFailure: '2024-08-30' },
    ],
    energy: [
      { time: '00:00', consumption: 120, prediction: 125 },
      { time: '04:00', consumption: 80, prediction: 85 },
      { time: '08:00', consumption: 200, prediction: 190 },
      { time: '12:00', consumption: 180, prediction: 185 },
      { time: '16:00', consumption: 220, prediction: 215 },
      { time: '20:00', consumption: 150, prediction: 155 },
    ],
    quality: [
      { name: 'Passed', value: 850 },
      { name: 'Minor Defects', value: 120 },
      { name: 'Major Defects', value: 20 },
      { name: 'Rejected', value: 10 },
    ],
    supplyChain: [
      { supplier: 'Supplier A', onTime: 95, delayed: 5 },
      { supplier: 'Supplier B', onTime: 88, delayed: 12 },
      { supplier: 'Supplier C', onTime: 92, delayed: 8 },
    ],
    employeePerformance: [
      { name: 'Team A', efficiency: 87, quality: 92 },
      { name: 'Team B', efficiency: 82, quality: 88 },
      { name: 'Team C', efficiency: 90, quality: 95 },
    ],
    productionRate: 100,
    alerts: [],
  });

  const addAlert = useCallback((message) => {
    setData(prevData => ({
      ...prevData,
      alerts: [...prevData.alerts.slice(-4), { id: Date.now(), message }]
    }));
  }, []);

  const updateInventory = useCallback((inventory) => 
    inventory.map(item => ({
      ...item,
      current: Math.max(0, item.current + Math.floor(Math.random() * 21) - 10)
    }))
  , []);

  const updateMaintenance = useCallback((maintenance) => 
    maintenance.map(machine => {
      const newHealth = Math.max(0, Math.min(100, machine.health + Math.floor(Math.random() * 5) - 2));
      const newNextMaintenance = Math.max(0, machine.nextMaintenance - 1);
      const daysToAdd = Math.floor((newHealth / 100) * 180);
      const newPredictedFailure = new Date(currentTime.getTime() + daysToAdd * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      
      if (newHealth < 70 || newNextMaintenance === 0) {
        addAlert(`Machine ${machine.name} needs maintenance. Health: ${newHealth}%`);
      }

      return { ...machine, health: newHealth, nextMaintenance: newNextMaintenance, predictedFailure: newPredictedFailure };
    })
  , [currentTime, addAlert]);

  const updateEnergy = useCallback((energy) => {
    const newConsumption = Math.floor(Math.random() * 150) + 100;
    const newPrediction = newConsumption + Math.floor(Math.random() * 21) - 10;
    const newData = [
      ...energy.slice(1),
      { time: currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }), 
        consumption: newConsumption, 
        prediction: newPrediction }
    ];

    if (newConsumption > 200) {
      addAlert(`High energy consumption detected: ${newConsumption} units`);
    }

    return newData;
  }, [currentTime, addAlert]);

  const updateQuality = useCallback((quality) => 
    quality.map(item => ({
      ...item,
      value: Math.max(0, item.value + Math.floor(Math.random() * 21) - 10)
    }))
  , []);

  const updateSupplyChain = useCallback((supplyChain) => 
    supplyChain.map(supplier => {
      const newOnTime = Math.min(100, Math.max(0, supplier.onTime + Math.floor(Math.random() * 5) - 2));
      const newDelayed = 100 - newOnTime;
      
      if (newDelayed > 15) {
        addAlert(`Supplier ${supplier.supplier} has high delay rate: ${newDelayed}%`);
      }

      return { ...supplier, onTime: newOnTime, delayed: newDelayed };
    })
  , [addAlert]);

  const updateEmployeePerformance = useCallback((employeePerformance) => 
    employeePerformance.map(team => ({
      ...team,
      efficiency: Math.min(100, Math.max(0, team.efficiency + Math.floor(Math.random() * 5) - 2)),
      quality: Math.min(100, Math.max(0, team.quality + Math.floor(Math.random() * 5) - 2))
    }))
  , []);

  const updateProductionRate = useCallback((productionRate) => 
    Math.max(0, productionRate + Math.floor(Math.random() * 11) - 5)
  , []);

  const updateData = useCallback(() => {
    setData(prevData => ({
      ...prevData,
      inventory: updateInventory(prevData.inventory),
      maintenance: updateMaintenance(prevData.maintenance),
      energy: updateEnergy(prevData.energy),
      quality: updateQuality(prevData.quality),
      supplyChain: updateSupplyChain(prevData.supplyChain),
      employeePerformance: updateEmployeePerformance(prevData.employeePerformance),
      productionRate: updateProductionRate(prevData.productionRate),
    }));
  }, [updateInventory, updateMaintenance, updateEnergy, updateQuality, updateSupplyChain, updateEmployeePerformance, updateProductionRate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
      updateData();
    }, 2000);

    return () => clearInterval(interval);
  }, [updateData]);

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-2">Workshop Facility - Predictive Intelligence System</h1>
      <p className="text-lg mb-4">Current Time: {currentTime.toLocaleString()}</p>
      <p className="text-xl mb-6">Production Rate: {data.productionRate} units/hour</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Box className="mr-2" />
            Real-time Inventory Levels
          </h2>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data.inventory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="current" fill="#8884d8" name="Current Stock" />
              <Bar dataKey="reorder" fill="#82ca9d" name="Reorder Point" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Wrench className="mr-2" />
            Live Predictive Maintenance
          </h2>
          {data.maintenance.map((machine, index) => (
            <div key={index} className="mb-2">
              <p className="font-semibold">{machine.name}</p>
              <div className="w-full bg-gray-200 rounded">
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded"
                  style={{ width: `${machine.health}%` }}
                >
                  {machine.health}%
                </div>
              </div>
              <p className="text-sm">Next Maintenance: {machine.nextMaintenance} days</p>
              <p className="text-sm">Predicted Failure: {machine.predictedFailure}</p>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Zap className="mr-2" />
            Real-time Energy Consumption & Prediction
          </h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data.energy}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="consumption" stroke="#8884d8" name="Actual" />
              <Line type="monotone" dataKey="prediction" stroke="#82ca9d" name="Predicted" strokeDasharray="5 5" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <CheckCircle className="mr-2" />
            Live Quality Control
          </h2>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={data.quality}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.quality.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Truck className="mr-2" />
            Real-time Supply Chain Tracking
          </h2>
          {data.supplyChain.map((supplier, index) => (
            <div key={index} className="mb-2">
              <p className="font-semibold">{supplier.supplier}</p>
              <div className="w-full bg-gray-200 rounded">
                <div
                  className="bg-green-600 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded"
                  style={{ width: `${supplier.onTime}%` }}
                >
                  {supplier.onTime}%
                </div>
              </div>
              <p className="text-sm">On-time Deliveries: {supplier.onTime}%</p>
              <p className="text-sm">Delayed Deliveries: {supplier.delayed}%</p>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Users className="mr-2" />
            Live Employee Performance
          </h2>
          {data.employeePerformance.map((team, index) => (
            <div key={index} className="mb-2">
              <p className="font-semibold">{team.name}</p>
              <p className="text-sm">Efficiency: {team.efficiency}%</p>
              <div className="w-full bg-gray-200 rounded">
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded"
                  style={{ width: `${team.efficiency}%` }}
                >
                  {team.efficiency}%
                </div>
              </div>
              <p className="text-sm">Quality: {team.quality}%</p>
              <div className="w-full bg-gray-200 rounded">
                <div
                  className="bg-green-600 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded"
                  style={{ width: `${team.quality}%` }}
                >
                  {team.quality}%
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6 bg-white p-4 rounded shadow">
        <h2 className="text-2xl font-bold mb-4">Real-time Alerts</h2>
        {data.alerts.length === 0 ? (
          <p>No current alerts.</p>
        ) : (
          data.alerts.map(alert => (
            <div key={alert.id} className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-2" role="alert">
              <p className="font-bold">Alert</p>
              <p>{alert.message}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Dashboard;